.show_more.addnote_list p {
    height: auto;
    overflow: hidden;
}

.show_more .note_dropdown {
    transform: rotate(180deg);
}

.addnote_list {
    border: 1px solid rgba(63, 39, 131, 0.2);
    border-radius: 10px;
    padding: 15px;
    position: relative;
    margin-top: 10px;
}
.addnote_list h5 {
    word-break: break-word;
    width: calc( 100% - 40px);
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 12px 0;
}
.addnote_list p {
    font-size: 12px;
    height: 29px;
    line-height: 14px;
    overflow: hidden;
    white-space: pre;
    word-break: break-all;
    max-width: 282px;
    text-overflow: ellipsis;
}
.show_more.addnote_list p {
    height: auto;
    overflow: hidden;
}

.addnote_list .note__btm_date {
    font-size: 10px;
    text-align: right;
    display: block;
    margin-top: 10px;
}

.note_dropdown {
    position: absolute;
    right: 15px;
    top: 15px;
    transition: all 0.4s ease;
}
.show_more .note_dropdown {
    transform: rotate(180deg);
}
.notify_msg_unread2 {
    position: relative;
    overflow: hidden; 
    background-color: #4027831e;
}
.notify_msg_unread2 * {
    z-index: 100;
}
.notify_msg_unread2::before {
    content: '';
    position: absolute;  
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%; 
    /* background: linear-gradient(90deg, white, rgba(255, 255, 255, 0.545));
    animation: slideAnimation 3s infinite linear; */
    color: black;
}

/* @keyframes slideAnimation {
    0% { left: -100%; }
    100% { left: 100%; }
} */

.first_button {
    animation: shake 4s cubic-bezier(.36, .07, .19, .97) both infinite;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    width: auto;
    border: 2px solid #3f2783;
    border-radius: 20px;
    background: #fff;
    color: #3f2783;
    padding: 14px;
    font-weight: 400;
    -webkit-animation: shake 4s cubic-bezier(.36, .07, .19, .97) both infinite;
}
  
  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  /* fontWeight: 500,
  //   marginLeft: 10,
  //   color: "#3f2783" */
  .power_off_text {
    color: transparent;
    background: linear-gradient(90deg, white 0%, #3f2783 20%);
    background-size: 200% 100%;
    background-clip: text;
    animation: slideEffect 5s infinite linear;
    -webkit-animation: slideEffect 5s infinite linear;
}

  .power_off_text2 {
    /*font-size: 20px;
    */margin-bottom: 20px;
    color: transparent;
    /*background: linear-gradient(90deg, white 0%, #FF7800 100%);
    */background: linear-gradient(90deg, #FF7800 0%, white 100%);
    background-size: 200% 100%;
    background-clip: text;
    animation: slideEffect 2s infinite linear;
   
    -webkit-animation: slideEffect 2s infinite linear;
}

@keyframes slideEffect {
    0% {
        background-position: 100% 0%;
    }
    100% {
        background-position: -100% 0%;
    }
}


